import { Form, Button, Row, Col, Radio, Space, Alert } from 'antd';
import { useState } from 'react';
import { useSelector } from "react-redux";

const colStyle = {
  padding: '8px 16px'
}

const First = ({ setValid, goBack, form }) => {

  const { labels } = useSelector(state => state.labels);
  const [ans0, setAns0] = useState({ type: '', feed: '' });
  const [ans1, setAns1] = useState({ type: '', feed: '' });
  const [ans2, setAns2] = useState({ type: '', feed: '' });
  const [ans3, setAns3] = useState({ type: '', feed: '' });
  const [ans4, setAns4] = useState({ type: '', feed: '' });

  const check = () => {
    const values = form.getFieldsValue();
    console.log(values);
    for (let v in values) {
      console.log(v + ": " + values[v]);
      if (!values[v]) return alert('SVA POLJA SU OBAVEZNA');
    }
    setValid(1);
  }

  const setAnswer = e => {
    let type = "success";
    let feed = '';
    switch (e.target.name) {
      case "0":
        type = e.target.value === "a" ? "warning" : "success";
        switch (e.target.value) {
          case "a": feed = labels.ANS_1a; break;
          case "b": feed = labels.ANS_1b; break;
          case "c": feed = labels.ANS_1c; break;
          case "d": feed = labels.ANS_1d; break;
          default: feed = ""; break;
        }
        setAns0({ type: type, feed: feed });
        break;
      case "1":
        type = e.target.value === "a" ? "success" : "warning";
        switch (e.target.value) {
          case "a": feed = labels.ANS_2a; break;
          case "b": feed = labels.ANS_2b; break;
          default: feed = ""; break;
        }
        setAns1({ type: type, feed: feed });
        break;
      case "2":
        type = e.target.value === "a" ? "success" : "warning";
        switch (e.target.value) {
          case "a": feed = labels.ANS_3a; break;
          case "b": feed = labels.ANS_3b; break;
          default: feed = ""; break;
        }
        setAns2({ type: type, feed: feed });
        break;
      case "3":
        type = e.target.value === "a" ? "success" : "warning";
        switch (e.target.value) {
          case "a": feed = labels.ANS_4a; break;
          case "b": feed = labels.ANS_4b; break;
          default: feed = ""; break;
        }
        setAns3({ type: type, feed: feed });
        break;
      case "4":
        type = "success";
        switch (e.target.value) {
          case "a": feed = labels.ANS_5a; break;
          case "b": feed = labels.ANS_5b; break;
          default: feed = ""; break;
        }
        setAns4({ type: type, feed: feed });
        break;
      default:
        break;
    }
  }

  return (
    <Form form={form} layout="vertical">
      <Row>
        <Col style={colStyle}>
          <Form.Item label={labels.INTRO_1} name="intro1">
            <Radio.Group buttonStyle='solid' size='small' onChange={setAnswer} name="0">
              <Space direction="vertical">
                <Radio value='a'>{labels.INTRO_1a}</Radio>
                <Radio value='b'>{labels.INTRO_1b}</Radio>
                <Radio value='c'>{labels.INTRO_1c}</Radio>
                <Radio value='d'>{labels.INTRO_1d}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!!ans0.type && <Alert type={ans0.type} message={ans0.feed} />}
        </Col>
      </Row>

      <Row>
        <Col style={colStyle}>
          <Form.Item label={labels.INTRO_2} name="intro2">
            <Radio.Group buttonStyle='solid' size='small' onChange={setAnswer} name="1">
              <Space direction="vertical">
                <Radio value='a'>{labels.INTRO_2a}</Radio>
                <Radio value='b'>{labels.INTRO_2b}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!!ans1.type && <Alert type={ans1.type} message={ans1.feed} />}
        </Col>
      </Row>

      <Row>
        <Col style={colStyle}>
          <Form.Item label={labels.INTRO_3} name="intro3">
            <Radio.Group buttonStyle='solid' size='small' onChange={setAnswer} name="2">
              <Space direction="vertical">
                <Radio value='a'>{labels.INTRO_3a}</Radio>
                <Radio value='b'>{labels.INTRO_3b}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!!ans2.type && <Alert type={ans2.type} message={ans2.feed} />}
        </Col>
      </Row>

      <Row>
        <Col style={colStyle}>
          <Form.Item label={labels.INTRO_4} name="intro4">
            <Radio.Group buttonStyle='solid' size='small' onChange={setAnswer} name="3">
              <Space direction="vertical">
                <Radio value='a'>{labels.INTRO_4a}</Radio>
                <Radio value='b'>{labels.INTRO_4b}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!!ans3.type && <Alert type={ans3.type} message={ans3.feed} />}
        </Col>
      </Row>

      <Row>
        <Col style={colStyle}>
          <Form.Item label={labels.INTRO_5} name="intro5">
            <Radio.Group buttonStyle='solid' size='small' onChange={setAnswer} name="4">
              <Space direction="vertical">
                <Radio value='a'>{labels.INTRO_5a}</Radio>
                <Radio value='b'>{labels.INTRO_5b}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!!ans4.type && <Alert type={ans4.type} message={ans4.feed} />}
        </Col>
      </Row>

      <Row>
        <Col span={12} style={colStyle}>
          <Button type="primary" onClick={() => goBack()}>{labels.PREV}</Button>
        </Col>
        <Col span={12} style={{ ...colStyle, textAlign: 'right' }}>
          <Button type="primary" onClick={check}>{labels.NEXT}</Button>
        </Col>
      </Row>

    </Form>
  );
}

export default First;