import { Form, Input, Button, Row, Col, Checkbox, Radio, Select, Modal, Divider } from 'antd';
import { useState } from 'react';
import { useSelector } from "react-redux";

const colStyle = {
  padding: '8px 16px'
}

const maxYear = new Date().getFullYear() - 18;
const minYear = maxYear - 33;
let years = [];
for (let y = maxYear; y > minYear; y--) years.push(y);

const countries = [
  { value: "BIH", label: "Bosnia and Herzegovina" },
  { value: "MNE", label: "Montenegro" },
  { value: "SRB", label: "Serbia" },
  { value: "SLO", label: "Slovenia" },
  { value: "OTH", label: "Other" },
]

const Personal = ({ setValid, form }) => {

  const { labels } = useSelector(state => state.labels);
  const [visible, setVisible] = useState(false);
  const [consent, setConsent] = useState(false);

  const check = () => {
    form.setFieldsValue({ consent: consent });
    const values = form.getFieldsValue();
    console.log(values);
    for (let v in values) {
      console.log(v + ": " + values[v]);
      if (!values[v]) return alert('SVA POLJA SU OBAVEZNA');
    }
    setValid(0);
  }

  return (
    <Form form={form} layout="vertical">
      <div className="intro">{labels.INTRO}</div>
      <Row>
        <Col span={12} style={colStyle}>
          <Form.Item label={labels.FIRST_NAME} name="firstName"><Input /></Form.Item>
        </Col>
        <Col span={12} style={colStyle}>
          <Form.Item label={labels.LAST_NAME} name="lastName"><Input /></Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} style={colStyle}>
          <Form.Item label={labels.EMAIL} name="email"><Input /></Form.Item>
        </Col>
        <Col span={12} style={colStyle}>
          <Form.Item label={labels.EMAIL2} name="email2"><Input /></Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="eml-desc">{labels.EML_DESC}</div>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={colStyle}>
          <Form.Item label={labels.PHONE} name="phone"><Input /></Form.Item>
        </Col>
        <Col span={12} style={colStyle}>
          <Form.Item label={labels.ADDRESS} name="address"><Input /></Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} style={colStyle}>
          <Form.Item label={labels.COUNTRY} name="country">
            <Select options={countries} />
          </Form.Item>
        </Col>
        <Col span={6} style={colStyle}>
          <Form.Item label={labels.CITY} name="city"><Input /></Form.Item>
        </Col>
        <Col span={6} style={colStyle}>
          <Form.Item label={labels.GENDER} name="gender">
            <Radio.Group buttonStyle='solid' size='small'>
              <Radio.Button value='Female'>Female</Radio.Button>
              <Radio.Button value='Male'>Male</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6} style={colStyle}>
          <Form.Item label={labels.BIRTHDAY} name="birthday">
            <Select mode="combobox">
              {years.map(y => <Select.Option key={y} value={y}>{y}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={16} style={colStyle}>
          <Form.Item label="" name="consent">
            <Checkbox checked={consent} onClick={() => setConsent(!consent)}>
              {labels.CONSENT}<Button type="link" onClick={() => setVisible(true)}>{labels.LINK}</Button>
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8} style={{ ...colStyle, textAlign: 'right' }}>
          <Button type="primary" onClick={check}>{labels.NEXT}</Button>
        </Col>
      </Row>

      <Modal
        width="80%"
        style={{ top: 20, textAlign: "center" }}
        title={<div className="title">{labels.PRIVACY_TITLE}</div>}
        open={visible}
        footer={[]}
        onCancel={() => setVisible(false)}>
        <div style={{ textAlign: 'left' }}><pre>{labels.PRIVACY_BODY}</pre></div>
      </Modal>
    </Form>
  );
}

export default Personal;