import { useSelector } from 'react-redux';

const AppTitle = () => {

  const { labels } = useSelector(state => state.labels);

  return (
    <div className="title">
      <div className="title-content">
        <div className="title-text">{labels.TITLE}</div>
        <div className="title-small">{labels.SUBTITLE}</div>
      </div>
    </div>
  );
}

export default AppTitle;