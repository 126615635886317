import { Form, Button, Row, Col, Radio } from 'antd';
import { useSelector } from "react-redux";

const colStyle = {
  padding: '8px 16px'
}
const title = {
  margin: '16px 8px',
  fontSize: '2em',
  color: '#3d4397'
}
const subTitle = {
  margin: '16px 8px',
  fontSize: '1.2em',
  color: '#3d4397'
}
const task = {
  backgroundColor: '#f0f0f0',
  padding: '8px',
  margin: '16px 0',
  borderRadius: '12px'
}

const Logical = ({ setValid, goBack, form }) => {

  const { quests } = useSelector(state => state.quests);
  const { labels } = useSelector(state => state.labels);

  const check = () => {
    const values = form.getFieldsValue();
    console.log(values);
    // for (let v in values) {
    //   if (!values[v]) return alert('SVA POLJA SU OBAVEZNA');
    // }
    setValid(2);
  }

  const imgPath = (fold, name) => {
    const path = `images/${fold}/${name}.jpg`;
    return <img src={path} alt={path} style={{height:50}} />;
  }

  return (
    <>
      {quests.length !== 0 &&
        <Form form={form} layout="vertical">

          <div style={title}>{labels.LOG_T1}</div>
          <div style={task}>
            <Form.Item label={quests[0].text} name="log01">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[0].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={quests[1].text} name="log02">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[1].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={quests[2].text} name="log03">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[2].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>

          <div style={title}>{labels.LOG_T2}</div>
          <div style={subTitle}>{labels.LOG_S2}</div>

          <div style={task}>
            <Form.Item label={quests[3].text} name="log04">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[3].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={quests[4].text} name="log05">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[4].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={quests[5].text} name="log06">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[5].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={quests[6].text} name="log07">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[6].answers.map(a => <Radio value={a.order}>{a.text}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>

          <div style={title}>{labels.LOG_T3}</div>
          <div style={subTitle}>{labels.LOG_S3}</div>

          <div style={task}>
            <Form.Item label={imgPath(quests[7].text, "question")} name="log08">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[7].answers.map(a => <Radio value={a.order}>{imgPath(quests[7].text, a.text)}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={imgPath(quests[8].text, "question")} name="log09">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[8].answers.map(a => <Radio value={a.order}>{imgPath(quests[8].text, a.text)}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={task}>
            <Form.Item label={imgPath(quests[9].text, "question")} name="log10">
              <Radio.Group buttonStyle='solid' size='small'>
                {quests[9].answers.map(a => <Radio value={a.order}>{imgPath(quests[9].text, a.text)}</Radio>)}
              </Radio.Group>
            </Form.Item>
          </div>

          <Row>
            <Col span={12} style={colStyle}>
              <Button type="primary" onClick={() => goBack()}>{labels.PREV}</Button>
            </Col>
            <Col span={12} style={{ ...colStyle, textAlign: 'right' }}>
              <Button type="primary" onClick={check}>{labels.NEXT}</Button>
            </Col>
          </Row>

        </Form>
      }
    </>
  );
}

export default Logical;