import { useState } from 'react';
import { Form, Steps } from "antd";
import { Personal, First, Logical, Welcome } from './steps';
import { useSelector } from 'react-redux';

const Aquisition = () => {

  const { labels } = useSelector(state => state.labels);
  const [step, setStep] = useState(0);
  const [valid, setValid] = useState([false, false, false, false]);
  const [form] = Form.useForm();

  const validate = index => {
    let oldAr = valid;
    valid[index] = true;
    setValid(oldAr);
    if(index < 4) setStep(index + 1);
  }

  const goBack = () => setStep(step - 1);

  const steps = [
    { title: labels.PERSONAL, content: () => (<Personal setValid={validate} form={form} />) },
    { title: labels.FIRST, content: () => (<First setValid={validate} form={form} goBack={goBack} />) },
    { title: labels.LOGICAL, content: () => (<Logical setValid={validate} form={form} goBack={goBack} />) },
    { title: labels.WELCOME, content: () => (<Welcome setValid={validate} form={form} goBack={goBack} />) }
  ];

  const onChange = step => {
    // if (step > 0 && !valid[0]) return setStep(0);
    // if (step > 1 && !valid[1]) return setStep(1);
    // if (step > 2 && !valid[2]) return setStep(2);
    setStep(step);
  }

  return (
    <div style={{ padding: '16px 16px 32px 16px' }}>
      <Steps current={step} items={steps} className="steps" onChange={onChange} />
      <Form />
      <div>{steps[step].content()}</div>
    </div>
  );
}

export default Aquisition;