import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quests: []
}

const questSlice = createSlice({
  name: "quest",
  initialState,
  reducers: {
  setQuests: (state, action) => {
      return {
        quests: action.payload
      }
    }
  }
});

const { reducer, actions } = questSlice;

export const { setQuests } = actions;

export default reducer;