import logo from 'assets/images/logo.svg';
import eng from 'assets/images/gb.svg';
import bih from 'assets/images/ba.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'store/labelsSlice';


const AppHeader = () => {

  const { language } = useSelector(state => state.labels);

  const dispatch = useDispatch();
  const clicked = () => dispatch(setLanguage(language === "bih" ? "eng" : "bih"));

  return (
    <header>
      <img src={logo} alt="logo" />
      {language === "bih" && <img src={eng} alt="eng" className="language" onClick={clicked} />}
      {language === "eng" && <img src={bih} alt="bih" className="language" onClick={clicked} />}
    </header>
  );
}

export default AppHeader;