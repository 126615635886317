import axios from 'axios';
import config from 'config';

const headers = () => {
  return {
    'content-type': 'application/json; charset=utf8',
    'pragma': 'no-cache, no-store, must-revalidate',
    'cache-control': 'no-cache',
    'expires': '0',
    'accept': 'application/json',
    'authorization': `Bearer ${config.token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  };
};

const auth = async function (credentials) {
  return await request('post', `${config.apiPath}login`, credentials);
};

const list = async function (dataSet) {
  let url = `${config.apiPath}${dataSet}`
  return await request('get', url, {});
};

const read = async function (dataSet, id) {
  return await request('get', `${config.apiPath}${dataSet}/${id}`, {});
};

const insert = async function (dataSet, data) {
  return await request('post', `${config.apiPath}${dataSet}`, data);
};

const update = async function (dataSet, data, id) {
  return await request(
    'put',
    `${config.apiPath}${dataSet}/${id}`,
    data
  );
};

const remove = async function (dataSet, id) {
  return await request(
    'delete',
    `${config.apiPath}${dataSet}/${id}`,
    {}
  );
};

const request = async function (method, url, data) {
  try {
    const res = await axios({
      method: method,
      url: url,
      data: data,
      headers: headers()
    });
    return res.data ? res.data : 200;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const join = a => {
  const mentors = config.mentors;
  let menstr = '';
  for (let i = 0; i < 5; i++) if (a[i]) menstr += mentors[i] + ', ';
  return menstr.substring(0, menstr.length - 2);
}

const extern = async function (url) {
  return await request('get', url, {});
};

const mega = size => {
  size = size / 1024;
  if(size < 1024) return Math.round(size) + 'K';
  size = Math.round(size / 10.24);
  return (size / 100) + 'M';
}

export { auth, list, read, insert, update, remove, headers, join, extern, mega };
