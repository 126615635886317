const envSubtitle = process.env.REACT_APP_SUBTITLE || "subtitle default";
const envAppBackend = process.env.REACT_APP_BACKEND || "https://localhost:44300/";
const envMentors = process.env.REACT_APP_MENTORS || "Crimson,Sherlock,Batman,Titan,General"

const config = {
  subTitle: envSubtitle,
  domain: envAppBackend,
  xlsPath: envAppBackend,
  apiPath: envAppBackend + 'api/',
  uplPath: envAppBackend + 'api/upload/',
  pdfPath: 'documents/',
  docPath: envAppBackend + 'documents/',
  currentUser: { id: 0, padawan: 0, name: '', role: '', code: '' },
  token: '',
  attemptRoute: '',
  messages: 5,
  filter: '',
  page: 1,
  mentors: envMentors.split(','),
  gender: { F: 'Female', M: 'Male' },
  status: { I: 'Inactive', A: 'Active',  B: 'Blocked' },
  grading: ['NotSubmitted', 'Submitted', 'Grading', 'Messaged', 'Accepted', 'OnCorrection', 'Corrected', 'Final']
};

export default config;