import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { list } from 'services/api';
import { setQuests } from 'store/questSlice';
import Aquisition from 'Aquisition';
import { AppHeader, AppTitle, AppFooter } from 'components';


function App() {

  const dispatch = useDispatch();

  const fetchQuest = async () => {
    const response = await list("questions");
    console.log(response);
    dispatch(setQuests(response));
  }

  useEffect(() => {
    fetchQuest()
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AppHeader />
      <AppTitle />
      <Aquisition />
      <AppFooter />
    </>
  );
}

export default App;
