import { createSlice } from "@reduxjs/toolkit";
import bihLabels from 'assets/labels/bih.json';
import engLabels from 'assets/labels/eng.json';

const initialState = {
  language: "bih",
  labels: bihLabels
}

const labelSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      return {
        language: action.payload,
        labels: action.payload === "bih" ? bihLabels : engLabels
      }
    }
  }
});

const { reducer, actions } = labelSlice;

export const { setLanguage } = actions;

export default reducer;