import { Col, Divider, Row } from 'antd';
import mistral from 'assets/images/mistral.svg';
import facebook from 'assets/images/facebook.png';
import instagram from 'assets/images/instagram.png';
import linkedin from 'assets/images/linkedin.png';

const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Row>
        <Col span={3} offset={2} align='left'>
          <p>Dodatni linkovi</p>
          <ul>
            <li>Cijena programa</li>
            <li>Mentori programa</li>
            <li>Tvorci programa</li>
            <li>Kontakt</li>
          </ul>
        </Col>
        <Col span={3} align='left'>
          <p>Legalno</p>
          <ul>
            <li>Uslovi korištenja</li>
            <li>Pravila o privatnosti</li>
            <li>Lični podaci</li>
            <li>Press kutak</li>
            <li>Konvencija o ravnopravost</li>
          </ul>
        </Col>
        <Col span={3} align='left'>
          <p>Resursi</p>
          <ul>
            <li>Brošura</li>
            <li>Infografika</li>
            <li>Arena shema</li>
            <li>FAQ</li>
          </ul>
        </Col>
        <Col span={9} align='left'>
          <p>Paragon newsletter</p>
          <p>Ovaj website je zaštićen sa Google reCAPTCHA sistemom. Pravila privatnosti i Pravila korištenja se podrazumijevaju.</p>
          <Divider />
          <p>Društvene mreže</p>
          <Row>
            <Col span={4}><img src={facebook} alt='facebook' /></Col>
            <Col span={4}><img src={instagram} alt='instagram' /></Col>
            <Col span={4}><img src={linkedin} alt='linkedin' /></Col>
            <Col span={12}>
              Powered by
              <a href="https://mistral.ba" target="_blank" rel="noreferrer noopener"><img alt="" width="112" height="16" src={mistral} /> </a>&copy; Paragon {currentYear},  all rights reserved.            </Col>
          </Row>
        </Col>
      </Row>

    </footer>
  );
}

export default AppFooter;

