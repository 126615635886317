import { Form, Input, Button } from 'antd';
import { useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import { insert } from 'services/api';

const Welcome = ({ form }) => {

  const { labels } = useSelector(state => state.labels);
  const { quests } = useSelector(state => state.quests);

  const finale = useCallback(async values => {
    console.clear();
    let ids = [];
    for (let i = 0; i < 10; i++) ids.push(quests[i].id);
    const data = { values, ids };
    console.log(data);
    const response = await insert("registrations", data);
    console.log(response);
    window.location.replace('https://dev.paragon.ba/cestitamo/');
  }, [quests]);

  useEffect(() => { finale(); }, [finale]);

  return (
    <>
      <div style={{ visibility: 'hidden' }}>
        <Form form={form} onFinish={finale}>
          <Form.Item label={labels.FIRST_NAME} name="firstName"><Input /></Form.Item>
          <Form.Item label={labels.LAST_NAME} name="lastName"><Input /></Form.Item>
          <Form.Item label={labels.EMAIL} name="email"><Input /></Form.Item>
          <Form.Item label={labels.EMAIL2} name="email2"><Input /></Form.Item>
          <Form.Item label={labels.PHONE} name="phone"><Input /></Form.Item>
          <Form.Item label={labels.ADDRESS} name="address"><Input /></Form.Item>
          <Form.Item label={labels.COUNTRY} name="country"><Input /></Form.Item>
          <Form.Item label={labels.CITY} name="city"><Input /></Form.Item>
          <Form.Item label={labels.GENDER} name="gender"><Input /></Form.Item>
          <Form.Item label={labels.BIRTHDAY} name="birthday"><Input /></Form.Item>
          <Form.Item label="Consent" name="consent"><Input /></Form.Item>
          <Form.Item label={labels.INTRO_1} name="intro1"><Input /></Form.Item>
          <Form.Item label={labels.INTRO_2} name="intro2"><Input /></Form.Item>
          <Form.Item label={labels.INTRO_3} name="intro3"><Input /></Form.Item>
          <Form.Item label={labels.INTRO_4} name="intro4"><Input /></Form.Item>
          <Form.Item label={labels.INTRO_5} name="intro5"><Input /></Form.Item>
          <Form.Item label={quests[0].id + ". test"} name="log01"><Input /></Form.Item>
          <Form.Item label={quests[1].id + ". test"} name="log02"><Input /></Form.Item>
          <Form.Item label={quests[2].id + ". test"} name="log03"><Input /></Form.Item>
          <Form.Item label={quests[3].id + ". test"} name="log04"><Input /></Form.Item>
          <Form.Item label={quests[4].id + ". test"} name="log05"><Input /></Form.Item>
          <Form.Item label={quests[5].id + ". test"} name="log06"><Input /></Form.Item>
          <Form.Item label={quests[6].id + ". test"} name="log07"><Input /></Form.Item>
          <Form.Item label={quests[7].id + ". test"} name="log08"><Input /></Form.Item>
          <Form.Item label={quests[8].id + ". test"} name="log09"><Input /></Form.Item>
          <Form.Item label={quests[9].id + ". test"} name="log10"><Input /></Form.Item>
          <Button htmlType="submit" type="primary">SUBMIT</Button>
        </Form>
      </div>
    </>
  );
}

export default Welcome;



