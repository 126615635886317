import { configureStore } from '@reduxjs/toolkit';
import labelsReducer from './labelsSlice';
import questsReducer from './questSlice';

const reducer = {
  labels: labelsReducer,
  quests: questsReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true
});

export default store;